const configs = {
  // 开发环境
  development: {
    baseURL: 'https://aqua.test.neolix.net/',
    baseURL2: 'https://scapi.test.neolix.net/',
    baseURL3: 'https://ndp.data.neolix.cn/service/test/ndp/aicar/',
    API_HighPrecisionLayer: 'https://ndp.data.neolix.cn/service/test/ndp/aiview/out/hdmap/tile', //  高精图层
    baseURL4: 'https://sdatacenter.test.neolix.net/',
    NDPURL: 'https://ndp.data.neolix.cn/service/dev/ndp/',
    API_AD_SERVER: 'https://ndp.data.neolix.cn/service/test',
    API_MapPathPlan: 'wss://ndp.data.neolix.cn/service/test/ndp/aipathplan/vehicle/mapPathPlan', // 多点routing服务
    API_MapRoadPathPlan:
      'wss://ndp.data.neolix.cn/service/test/ndp/aipathplan/vehicle/road/mapPathPlan', //  关键点服务1
    API_MapRoadPathPlanV2:
      'wss://ndp.data.neolix.cn/service/test/ndp/aipathplan/vehicle/v2/road/mapPathPlan', // 关键点服务2
    API_MapRoadPathPlanV3:
      'wss://ndp.data.neolix.cn/service/test/ndp/aipathplan/vehicle/v3/road/mapPathPlan', //关键点服务3
  },
  // 测试环境
  test: {
    baseURL: 'https://aqua.test.neolix.net/',
    baseURL2: 'https://scapi.test.neolix.net/',
    baseURL3: 'https://ndp.data.neolix.cn/service/test/ndp/aicar/',
    API_HighPrecisionLayer: 'https://ndp.data.neolix.cn/service/test/ndp/aiview/out/hdmap/tile', //  高精图层
    baseURL4: 'https://sdatacenter.test.neolix.net/',
    NDPURL: 'https://ndp.data.neolix.cn/service/dev/ndp/',
    API_AD_SERVER: 'https://ndp.data.neolix.cn/service/test',
    API_MapPathPlan: 'wss://ndp.data.neolix.cn/service/test/ndp/aipathplan/vehicle/mapPathPlan', // 多点routing服务
    API_MapRoadPathPlan:
      'wss://ndp.data.neolix.cn/service/test/ndp/aipathplan/vehicle/road/mapPathPlan', //  关键点服务1
    API_MapRoadPathPlanV2:
      'wss://ndp.data.neolix.cn/service/test/ndp/aipathplan/vehicle/v2/road/mapPathPlan', // 关键点服务2
    API_MapRoadPathPlanV3:
      'wss://ndp.data.neolix.cn/service/test/ndp/aipathplan/vehicle/v3/road/mapPathPlan', //关键点服务3
  },
  // 预发环境
  uat: {
    baseURL: 'https://aqua.uat.neolix.net/',
    baseURL2: 'https://scapi.neolix.net/',
    baseURL3: 'https://ndp.data.neolix.cn/service/pro/ndp/aicar/',
    API_HighPrecisionLayer: 'https://ndp.data.neolix.cn/service/pro/ndp/aiview/out/hdmap/tile', //  高精图层
    baseURL4: 'https://datacenter.neolix.net/',
    NDPURL: 'https://ndp.data.neolix.cn/service/pro/ndp/',
    API_AD_SERVER: 'https://ndp.data.neolix.cn/service/pro',
    API_MapPathPlan: 'wss://ndp.data.neolix.cn/service/test/ndp/aipathplan/vehicle/mapPathPlan', // 多点routing服务
    API_MapRoadPathPlan:
      'wss://ndp.data.neolix.cn/service/pro/ndp/aipathplan/vehicle/road/mapPathPlan', //  关键点服务1
    API_MapRoadPathPlanV2:
      'wss://ndp.data.neolix.cn/service/pro/ndp/aipathplan/vehicle/v2/road/mapPathPlan', // 关键点服务2
    API_MapRoadPathPlanV3:
      'wss://ndp.data.neolix.cn/service/pro/ndp/aipathplan/vehicle/v3/road/mapPathPlan', //关键点服务3
  },

  // 生产环境
  production: {
    baseURL: 'https://aqua.cybertron.neolix.net/',
    baseURL2: 'https://scapi.neolix.net/',
    baseURL3: 'https://ndp.data.neolix.cn/service/pro/ndp/aicar/',
    API_HighPrecisionLayer: 'https://ndp.data.neolix.cn/service/pro/ndp/aiview/out/hdmap/tile', //  高精图层
    baseURL4: 'https://datacenter.neolix.net/',
    NDPURL: 'https://ndp.data.neolix.cn/service/pro/ndp/',
    API_AD_SERVER: 'https://ndp.data.neolix.cn/service/pro',
    API_MapRoadPathPlan:
      'wss://ndp.data.neolix.cn/service/pro/ndp/aipathplan/vehicle/road/mapPathPlan', //  关键点服务1
    API_MapRoadPathPlanV2:
      'wss://ndp.data.neolix.cn/service/pro/ndp/aipathplan/vehicle/v2/road/mapPathPlan', // 关键点服务2
    API_MapRoadPathPlanV3:
      'wss://ndp.data.neolix.cn/service/pro/ndp/aipathplan/vehicle/v3/road/mapPathPlan', //关键点服务3
  },
};
const DEPLOYMENT_ENV = process.env.VUE_APP_DEPLOYMENT_ENV
  ? process.env.VUE_APP_DEPLOYMENT_ENV
  : 'development';

const env = configs[DEPLOYMENT_ENV];
export default env;
