import Vue from 'vue';
import App from './App.vue';
import router from './router';
import * as utils from '@/utils';
import * as filters from './filters';
import * as echarts from 'echarts';
// import * as echarts from "echarts/core";
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import Loading from './components/Loading';
import loadingOpendoor from './components/LoadingOpenDoor';
import loadingCircle from './components/LoadingCircle';
import Toast from '@/components/toast';
import Message from 'vue-m-message';
import 'vue-m-message/dist/index.css'; //https://mengdu.github.io/m-message/index.html#/
import Vant from 'vant';
import InfiniteLoading from 'vue-infinite-loading';
import FastClick from 'fastclick';
import smoothscroll from 'smoothscroll-polyfill';
// import "babel-polyfill";
import '@babel/polyfill';
import Vconsole from 'vconsole';
import 'vant/lib/index.css';
import './utils/ployfill'; // 注意文件路径
import './global.css';
import store from './store';
import permission from '@/directive/permission/index.js';
import '@/styles/main.scss';

smoothscroll.polyfill();
FastClick.attach(document.body);

FastClick.prototype.focus = function (targetElement) {
  let length;
  if (
    targetElement.setSelectionRange &&
    targetElement.type.indexOf('date') !== 0 &&
    targetElement.type !== 'time' &&
    targetElement.type !== 'month'
  ) {
    length = targetElement.value.length;
    targetElement.focus();
    targetElement.setSelectionRange(length, length);
  } else {
    targetElement.focus();
  }
};
// 注册必须的组件
echarts.use([PieChart, CanvasRenderer]);
Vue.use(permission);
Vue.use(Message);
Vue.use(Loading);
Vue.use(loadingOpendoor);
Vue.use(loadingCircle);
Vue.use(Toast);
Vue.use(Vant);
Vue.use(InfiniteLoading);
// const vConsole = new Vconsole();
// Vue.use(vConsole);
Vue.config.productionTip = false;
Vue.prototype.utils = utils;
//把价格拆分成整数和小数（包括小数点）
Vue.prototype.$splitPrice = (price) => {
  let p = `${price}`.split('.');
  return [
    p[0],
    p[0] === '0' && !p[1] ? '' : `.${p[1] || 0}`, //整数是0则不补0，否则补0
  ];
};
Vue.prototype.$echarts = echarts;
// new Vconsole()
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
