/*
 * @Author: zhangqingyuan flstack@163.com
 * @Date: 2025-03-07 14:17:54
 * @LastEditors: zhang flstack@163.com
 * @LastEditTime: 2025-03-27 14:54:09
 * @FilePath: /neolixapph5/src/utils/auth.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import sha1 from 'crypto-js/sha1';

// const TOKEN = "fb8305f6-c619-4f13-a74e-2eb37a935d34";
// const TOKEN = "neolixxcx123";
// const TOKEN = '94a973a7-978a-47aa-bfa5-56da8d683089';
// const TOKEN = 'ccff9a19-5b71-43f6-9282-3fe9ff4faa99';
// 07 普通用户：fe88e68e-0f23-4816-a405-cb099659ed15     15801654807    q12345678-
// 01 专家  ：7eee0df7-6f0e-46fe-8d50-9e69290f9d34    158016548071   q12345678-
// 03 专家  ：10dd44f5-4cc9-42dd-b33c-421726eace57     15801654803    q12345678-
// 02 专员：35e39417-6bdc-49f6-b043-e4d985558eb7        15801654802    q12345678-
const TOKEN = '10dd44f5-4cc9-42dd-b33c-421726eace57';
// const TOKEN = '35e39417-6bdc-49f6-b043-e4d985558eb7';
// const TOKEN = '9e2f29b3-61a2-40e4-92df-899b4eacad64';
const SECRET = '12edfbaf-3b34-555b-9b7b-0d1e5904d670';
const USERID = 1;

export function getToken() {
  return sessionStorage.getItem('_token');
  // return "neolixxcx123";
}

export function setToken(token, appSecret, userId) {
  if (process.env.NODE_ENV === 'development') {
    sessionStorage.setItem('_token', TOKEN);
    appSecret && sessionStorage.setItem('_secret', SECRET);
    userId && sessionStorage.setItem('_userId', USERID);
    return;
  }

  if (token == getToken() || !token) return;
  sessionStorage.setItem('_token', token);
  appSecret && sessionStorage.setItem('_secret', appSecret);
  // userId && sessionStorage.setItem("_userId", String(userId));
}

export function getAppSecret() {
  return sessionStorage.getItem('_secret');
  // return "12edfbaf-3b34-555b-9b7b-0d1e5904d670";
}

// 鉴权 生成sha1字符串
const sha1Crypto = (timestamp, nonce) =>
  sha1(
    [timestamp, nonce, getAppSecret()].sort().reduce((a, b) => a + '' + b),
    ''
  )
    .toString()
    .toUpperCase();

const randomNumber = () => parseInt((Math.random() * 9 + 1) * 10000);

export const encrypt = () => {
  const nonce = randomNumber();
  const timestamp = new Date().getTime();

  const signature = sha1Crypto(timestamp, nonce);
  return { nonce, timestamp, signature };
};
